import {environmentShared} from './environment.shared';

export const environment = {
    production: true,
    baseUrl: '',
    apiBaseUrl: environmentShared.apiBaseUrl,
    lastVisitedAddressKey: environmentShared.lastVisitedAddressKey,
    environmentName: environmentShared.environmentName,
    apiKey: environmentShared.apiKey,
    version: environmentShared.version,
    updateDate: environmentShared.updateDate,
    google_forms: environmentShared.google_forms,
    assetsServiceUrl: environmentShared.assetsServiceUrl,
};