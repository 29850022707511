import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { init, instrumentAngularRouting, BrowserTracing } from '@sentry/angular-ivy';
import { AppModule } from './app/common';
import { environment } from "./environments/";
import { register } from 'swiper/element/bundle';

register()
init({
  dsn: "https://77e1a5847d2c4843900e7121900feab5@o1420641.ingest.sentry.io/6768690",
  // release: "Specify release name",
  environment: "qa",
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracingOrigins: ["localhost", "https://yourserver.io/api"],
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
