export const FirebaseConfig = {
    MemberAppConfig: {
        'projectId': 'qa-bwrm-prism',
        'appId': '1:753042006577:web:7e423656da3f5ce9afeb10',
        'databaseURL': 'https://qa-bwrm-prism.firebaseio.com',
        'storageBucket': 'qa-bwrm-prism',
        'locationId': 'us-central',
        'apiKey': 'AIzaSyBeOy_mAt6qZ8uWCT4Cm9RcGOK4Bn4Isgw',
        'authDomain': 'qa-bwrm-prism.firebaseapp.com',
        'messagingSenderId': '753042006577',
        'measurementId': 'G-Z79BKBEVHT'
    },
    memberFCMPublicVapidKey: 'BCSA5Axmxo5ZkHG6t7t3wI-g84ftBwIhXwg7WKvo1PlUrNEmdghG2eRlNqr4MQtfWfF6GwOJea4_hXXCV3hRw08',
    ProcessorAppConfig: {
        'projectId': 'qa-bwrm-processor-prism',
        'databaseURL': 'https://qa-bwrm-processor-prism.firebaseio.com',
        'storageBucket': 'qa-bwrm-processor-prism',
        'locationId': 'us-central',
        'apiKey': 'AIzaSyCQMdqDzC9LamT0oysViqvODzILwxe7jYw',
        'authDomain': 'qa-bwrm-processor-prism.firebaseapp.com',
        'messagingSenderId': '343320850821'
    }
};