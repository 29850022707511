import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AssetsService, SnackbarService} from '../../services';
import {map} from 'rxjs/operators';
import {Response} from '../../models';
import {SwiperContainer} from 'swiper/element/swiper-element';

@Component({
    selector: 'prism-mobile-swiper',
    templateUrl: './mobile-swiper.component.html',
    styleUrls: ['./mobile-swiper.component.scss']
})
export class MobileSwiperComponent implements OnInit, AfterViewInit {
    @ViewChild('usefulSwiper', {static: false}) usefulSwiper: SwiperContainer;
    @ViewChild('videoPlayer', {static: false}) videoElement: ElementRef<HTMLVideoElement>;
    @Input() productData;
    @Input() jobData;
    @Input() initialIndex: any;
    @Input() mobileData: { isMobile: boolean, browser: string, os: string };
    @Input() assets;
    @Output() closeGallery: EventEmitter<void> = new EventEmitter<void>();
    assetsList = [];
    address: string = null;
    memberProfileUid: string = null;
    jobId: number;
    caterForIOSStyle: boolean;

    constructor(
        private snackbarService: SnackbarService,
        private assetsService: AssetsService) {
    }

    ngOnInit() {
        let pathsToFinal = this.assets.map((asset) => {
            return asset.originalFilePath;
        });

        this.assetsService.getProcessedAssetFinalSignedUrls(pathsToFinal)
            .pipe(
                map(((res: Response) => {
                    let {pathsToFinal} = res.data;
                    return pathsToFinal.map((res: any, index) => {
                        return {
                            ...this.assets[index],
                            ...res
                        };
                    });
                }))
            ).subscribe((assetsList) => {
            this.assetsList = assetsList;
        });

        const {os, browser} = this.mobileData;
        this.caterForIOSStyle = browser === 'Safari' && os === 'iOS';

    }

    ngAfterViewInit() {
        if (!this.caterForIOSStyle) {
            this.makeFullScreen();
        }
    }

    requestClose() {
        this.closeGallery.emit();
    }

    requestDownload() {
        const assetIndex = this.usefulSwiper.swiper.realIndex;
        const asset = this.assets[assetIndex];
        const {originalFilePath, webFilePath, watermarkFilePath} = asset;
        const downloadPaths = [];

        !!originalFilePath && downloadPaths.push(originalFilePath);
        !!webFilePath && downloadPaths.push(webFilePath);
        !!watermarkFilePath && downloadPaths.push(watermarkFilePath);

        this.assetsService.sendAgencyDownloadRequest(this.jobData.address, `job_${this.jobData.jobId}`, downloadPaths);
        this.snackbarService.showDismissibleSnackbar();

    }

    makeFullScreen() {
        const doc: any = window.document;
        const docEl = doc.documentElement;
        const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullscreen || docEl.msRequestFullscreen;
        requestFullScreen.call(docEl);
    }
}
