import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import Dropzone from 'dropzone';
import isEmpty from 'lodash/isEmpty';
import {Agency, Response} from '../../models';
import {CustomValidators} from '../../validators';
import {AgencyService, AssetsService, MapsAPILoader, SnackbarService} from '../../services';
import {
    ALLOWED_WATERMARK_FILE_TYPES,
    ASPECT_RATIO_3to2,
    ASPECT_RATIO_4to3,
    EMAIL_REGEX,
    MAX_FILE_SIZE_BYTES,
    PRESET_WEB_SIZES,
    refreshJobs$,
    refreshJobsUsers$,
    validateEmailList
} from '../../helpers';

declare var google: any;

@Component({
    selector: 'prism-add-edit-agency',
    templateUrl: './add-edit-agency.component.html',
    styleUrls: ['./add-edit-agency.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditAgencyComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('dropzone', {static: true}) dropzone: ElementRef<HTMLDivElement>;
    @ViewChild('watermark', {static: true}) watermarkImage: ElementRef<HTMLImageElement>;
    @ViewChild('chipListNotification', {static: true}) chipListNotification;
    @ViewChild('chipListInvoice', {static: true}) chipListInvoice;

    private agencyId: string;
    private watermarkFile: any;
    private readonly destroyLocalWatermarkSub$;
    watermarkRemoveRequested: boolean;
    isPosting: boolean;
    localWatermarkUrls;
    addressVerified: BehaviorSubject<boolean>;
    isVerifying: BehaviorSubject<boolean>;
    selectedAspectRatio: number;
    selectedSizeIndex: number;
    currentWatermarkTrueSize: { width: number, height: number };
    autocompleteService: any;
    geocoder: any;
    autoCompleteOptions: BehaviorSubject<string[]>;
    watermarkFound: boolean;
    hasUnsavedWatermark: boolean;
    notificationEmailListHasError = false;
    invoiceEmailListHasError = false;
    aspectRatio3to2: number;
    aspectRatio4to3: number;
    watermarkDropzone: Dropzone;
    allowedFileTypes: string[];
    dZoneConfig: Dropzone.DropzoneOptions;
    agencyForm: FormGroup;
    primaryDetailsFormGroup: FormGroup;
    emailsFormGroup: FormGroup;
    imageSizeFormGroup: FormGroup;
    watermarkFormGroup: FormGroup;
    invoiceEmails: FormArray;
    notificationEmails: FormArray;
    dummyBackgroundPixelSize: number;
    separatorKeysCodes = [ENTER, COMMA, SPACE];
    presetImageSizes: { width: number, height: number, ratio: number }[];
    loadingWatermark: boolean;
    watermarkImageSize: { width: number, height: number };
    localWatermarkUrls$: Observable<any>;
    subDestroyer$: Subject<void>;

    constructor(private dialogRef: MatDialogRef<AddEditAgencyComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Agency,
                private formBuilder: UntypedFormBuilder,
                private agencyService: AgencyService,
                private snackbarService: SnackbarService,
                private mapsAPILoader: MapsAPILoader,
                private changeRef: ChangeDetectorRef,
                private assetsService: AssetsService) {

        this.invoiceEmails = new FormArray([], [Validators.required, CustomValidators.emailList]);
        this.notificationEmails = new FormArray([], [Validators.required, CustomValidators.emailList]);

        if (data && data.invoiceEmails && data.invoiceEmails.length) {
            this.fillEmails(data.invoiceEmails, this.invoiceEmails);
        }
        if (data && data.notificationEmails && data.notificationEmails.length) {
            this.fillEmails(data.notificationEmails, this.notificationEmails);
        }

        this.primaryDetailsFormGroup = formBuilder.group({
            name: formBuilder.control(data ? data.name : '', [Validators.required]),
            contactNumber: formBuilder.control(data ? data.contactNumber : ''),
            address: formBuilder.control(data ? data.address : '', [Validators.required]),
            notes: formBuilder.control(!!data?.notes ? JSON.parse(data.notes) : '')
        });
        this.emailsFormGroup = formBuilder.group({
            invoiceEmails: this.invoiceEmails,
            notificationEmails: this.notificationEmails
        });
        this.imageSizeFormGroup = formBuilder.group({
            width: formBuilder.control({value: data ? data.imageSize.width : 0, disabled: true}, [Validators.required, Validators.min(1)]),
            height: formBuilder.control({
                value: data ? data.imageSize.height : 0,
                disabled: true
            }, [Validators.required, Validators.min(1)]),
            ratio: formBuilder.control({value: data ? data.imageSize.ratio : this.selectedAspectRatio})
        });
        this.watermarkFormGroup = formBuilder.group({
            size: formBuilder.control(50, [Validators.required, Validators.min(0), Validators.max(100)]),
            opacity: formBuilder.control(100, [Validators.required, Validators.min(0), Validators.max(100)]),
            offsetX: formBuilder.control(0, [Validators.required, Validators.min(0), Validators.max(100)]),
            offsetY: formBuilder.control(0, [Validators.required, Validators.min(0), Validators.max(100)])
        });

        this.agencyForm = formBuilder.group({
            primaryDetails: this.primaryDetailsFormGroup,
            emails: this.emailsFormGroup,
            imageSize: this.imageSizeFormGroup,
            watermark: this.watermarkFormGroup
        });
        this.watermarkRemoveRequested = false;
        this.localWatermarkUrls = {};
        this.addressVerified = new BehaviorSubject<boolean>(false);
        this.isVerifying = new BehaviorSubject<boolean>(false);
        this.selectedAspectRatio = ASPECT_RATIO_3to2;
        this.selectedSizeIndex = 0;
        this.currentWatermarkTrueSize = {width: 0, height: 0};
        this.autoCompleteOptions = new BehaviorSubject<string[]>([]);
        this.notificationEmailListHasError = false;
        this.invoiceEmailListHasError = false;
        this.aspectRatio3to2 = ASPECT_RATIO_3to2;
        this.aspectRatio4to3 = ASPECT_RATIO_4to3;
        this.allowedFileTypes = Object.values(ALLOWED_WATERMARK_FILE_TYPES);
        this.dZoneConfig = {
            url: '#',
            maxFiles: 1,
            autoQueue: false,
            parallelUploads: 1,
            uploadMultiple: false,
            autoProcessQueue: false,
            createImageThumbnails: true,
            acceptedFiles: 'image/png',
            chunking: false,
            dictDefaultMessage: ''
        };
        this.dummyBackgroundPixelSize = 260;
        this.watermarkFile = null;
        this.destroyLocalWatermarkSub$ = new Subject<void>();
        this.presetImageSizes = Object.values(PRESET_WEB_SIZES);
        this.watermarkImageSize = {width: 400, height: 200};
        this.subDestroyer$ = new Subject<void>();
    }

    ngOnInit() {
        this.localWatermarkUrls$ = this.assetsService.watermarks$
            .pipe(
                takeUntil(this.destroyLocalWatermarkSub$),
            );
        if (this.data && this.data.id) {
            this.agencyId = this.data.id;
            if (this.data.watermarkDimensions) {
                this.watermarkImageSize = this.data.watermarkDimensions;
                this.currentWatermarkTrueSize = {
                    width: this.watermarkImageSize.width,
                    height: this.watermarkImageSize.height
                };
            }
            this.watermarkFormGroup.get('size').setValue(this.data.watermarkSize || 100);
            this.watermarkFormGroup.get('opacity').setValue(this.data.watermarkOpacity || 100);
            if (this.data.watermarkOffset) {
                this.watermarkFormGroup.get('offsetX').setValue(this.data.watermarkOffset.offsetX);
                this.watermarkFormGroup.get('offsetY').setValue(this.data.watermarkOffset.offsetY);
            }
            this.initLocalWatermarkSub();
            if (this.data.hasWatermark) {
                this.getAgencyWatermark(this.data.id);
            }
        }

        this.initLocalWatermarkSub();


        this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
        this.chipListInvoice.errorState = !validateEmailList(this.invoiceEmails);

        const previousIndex: number = this.presetImageSizes.findIndex(size => {
            if (this.data) {
                return size.width === this.data.imageSize.width && size.height === this.data.imageSize.height;
            }
            return false;
        });
        if (previousIndex === -1 && this.data && this.data.imageSize) {
            this.onSizeSelectChanged(previousIndex);
            this.onAspectRatioChanged(this.data.imageSize.ratio);
        } else if (previousIndex !== this.selectedSizeIndex && this.data && this.data.imageSize) {
            this.onSizeSelectChanged(previousIndex);
        } else {
            this.onSizeSelectChanged(this.selectedSizeIndex);
        }

        this.mapsAPILoader.load()
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(() => {
                this.autocompleteService = new google.maps.places.AutocompleteService();
                this.geocoder = new google.maps.Geocoder();
                if (this.data && this.data.address) {
                    this.isVerifying.next(true);
                    if (this.data) {
                        this.geocoder.geocode({
                            address: this.data.address,
                        }, this.verifyGeocoderResult);
                    }
                }

                this.primaryDetailsFormGroup.get('address').valueChanges.pipe(debounceTime(100), distinctUntilChanged())
                    .subscribe(val => {
                        this.isVerifying.next(true);
                        if (val) {
                            this.geocoder.geocode({
                                address: val,
                            }, this.verifyGeocoderResult);

                            this.autocompleteService.getPlacePredictions({
                                types: ['address'],
                                componentRestrictions: {country: ['au', 'nz']},
                                input: val
                            }, this.handleAutocompleteResponse);
                        } else {
                            this.isVerifying.next(false);
                            this.addressVerified.next(false);
                        }
                    });
            });
    }

    ngAfterContentInit() {
        this.dZoneConfig.accept = ((file: any, done) => {
            if (this.allowedFileTypes.includes(file.type)) {
                done();
            } else {
                this.snackbarService.handleError(`The file type is not allowed.`);
                this.watermarkDropzone.removeFile(file);
                done('Nope');
            }
        });

        this.watermarkDropzone = new Dropzone(this.dropzone.nativeElement, this.dZoneConfig);

        this.watermarkDropzone.on('dragover', () => this.dropzone.nativeElement.classList.add('drag-over'));
        this.watermarkDropzone.on('dragleave', () => this.dropzone.nativeElement.classList.remove('drag-over'));
        this.watermarkDropzone.on('drop', () => this.dropzone.nativeElement.classList.remove('drag-over'));

        this.watermarkDropzone.on('thumbnail', (file: any) => {
            if (file.size > MAX_FILE_SIZE_BYTES) {
                this.snackbarService.handleError(`Max file size exceeded. Please keep watermark images below ${MAX_FILE_SIZE_BYTES / 1000}kb`);
                this.watermarkDropzone.removeFile(file);
            } else {
                this.hasUnsavedWatermark = true;
                this.watermarkFile = file;
                this.currentWatermarkTrueSize = {
                    width: file.width,
                    height: file.height
                };
                this.watermarkRemoveRequested = false;
                this.assetsService.setWatermarkLocal('temp', file['dataURL']);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.agencyId) {
            this.assetsService.setWatermarkLocal('temp', null);
            this.assetsService.setWatermarkLocal(this.agencyId, null);
        }
        this.destroyLocalWatermarkSub$.next();
        this.subDestroyer$.next();
    }

    initLocalWatermarkSub() {
        this.localWatermarkUrls$
            .pipe(
                takeUntil(this.destroyLocalWatermarkSub$)
            )
            .subscribe(watermarkObj => {
                this.localWatermarkUrls = watermarkObj;
                if (this.data) {
                    this.watermarkFound = !isEmpty(watermarkObj[this.data.id]);
                }

            });
    }

    getAgencyWatermark(agencyId) {
        this.loadingWatermark = true;
        this.assetsService.getAgencyWatermark(agencyId)
            .toPromise()
            .then((res: Response) => {
                const watermarks = this.assetsService.watermarks;
                this.watermarkFound = !isEmpty(watermarks[agencyId]);
                this.assetsService.setWatermarkLocal(this.data.id, res.data);
                this.loadingWatermark = false;
            })
            .catch(err => {
                this.loadingWatermark = false;
                console.warn('Error fetching watermark:', err);
                this.snackbarService.handleError('Error fetching agency watermark');
            });
    }

    setWatermarkForRemoval() {
        if (!!this.data) {
            const agencyId = this.data.id;
            this.assetsService.setWatermarkLocal(`${agencyId}`, null);
        }

        this.destroyLocalWatermarkSub$.next();
        this.hasUnsavedWatermark = false;
        this.watermarkFound = false;
        this.watermarkFile = null;
        this.watermarkRemoveRequested = true;
        this.assetsService.setWatermarkLocal('temp', null);
        this.localWatermarkUrls$ = this.assetsService.watermarks$
            .pipe(
                takeUntil(this.destroyLocalWatermarkSub$),
            );
    }

    verifyGeocoderResult = (resultList) => {
        if (resultList && Array.isArray(resultList) && resultList.length) {
            const address = this.primaryDetailsFormGroup.get('address').value.toLowerCase();
            const matchingResult = resultList.find(result => {
                if (result.address_components && result.address_components.length >= 3) {
                    return [result.address_components[0], result.address_components[1], result.address_components[2]].every(component => {
                        return (address.includes(component.long_name.toLowerCase()) || address.includes(component.short_name.toLowerCase()));
                    });
                } else {
                    return false;
                }
            });
            if (matchingResult) {
                this.addressVerified.next(true);
            } else {
                this.addressVerified.next(false);
            }
            this.isVerifying.next(false);
        } else {
            this.addressVerified.next(false);
            this.isVerifying.next(false);
        }
    };

    handleAutocompleteResponse = (placeList) => {
        if (placeList && Array.isArray(placeList) && placeList.length) {
            this.autoCompleteOptions.next(placeList.map(place => place.description));
        }
    };

    fillEmails(emails: string[], control: UntypedFormArray) {
        for (const email of emails) {
            control.push(this.formBuilder.control(email, [Validators.email]));
        }
    }

    addInvoiceEmail(event: MatChipInputEvent) {
        if (event.value) {
            if (this.checkInvoiceEmailValidity(event.value)) {
                this.chipListInvoice.errorState = false;
                this.invoiceEmails.push(this.formBuilder.control(event.value.trim(), [Validators.pattern(EMAIL_REGEX)]));
                event.input.value = '';
            } else {
                this.chipListInvoice.errorState = true;
            }
        } else {
            this.chipListInvoice.errorState = false;
        }
    }

    addNotificationEmail(event: MatChipInputEvent) {
        if (event.value) {
            if (this.checkNotificationEmailValidity(event.value)) {
                this.notificationEmails.push(this.formBuilder.control(event.value.trim(), [Validators.pattern(EMAIL_REGEX)]));
                this.chipListNotification.errorState = false;
                event.input.value = '';
            } else {
                this.chipListNotification.errorState = true;
            }
        }
    }

    removeInvoiceEmail(index: number) {
        if (index >= 0 && index < this.invoiceEmails.length) {
            this.invoiceEmails.removeAt(index);

            if (this.invoiceEmails.getRawValue().length) {
                this.chipListInvoice.errorState = !validateEmailList(this.invoiceEmails);
            } else {
                this.chipListInvoice.errorState = true;
            }
        }
    }

    removeNotificationEmail(index: number) {
        if (index >= 0 && index < this.notificationEmails.length) {
            this.notificationEmails.removeAt(index);

            if (this.notificationEmails.getRawValue().length) {
                this.chipListNotification.errorState = !validateEmailList(this.notificationEmails);
            } else {
                this.chipListNotification.errorState = true;
            }
        }
    }

    onSizeSelectChanged(index: number) {
        this.selectedSizeIndex = +index;
        const widthControl = this.imageSizeFormGroup.get('width');
        const heightControl = this.imageSizeFormGroup.get('height');
        // Custom Size
        if (this.selectedSizeIndex === -1) {
            const w = +this.imageSizeFormGroup.get('width').value;
            const h = Math.round(w / this.selectedAspectRatio);
            this.onAspectRatioChanged(this.selectedAspectRatio);
            widthControl.enable();
            heightControl.setValue(h);
        } else {
            if (this.selectedSizeIndex >= 0 && this.selectedSizeIndex < this.presetImageSizes.length) {
                const imageSize = this.presetImageSizes[this.selectedSizeIndex];
                this.onAspectRatioChanged(imageSize.ratio);
                widthControl.disable();
                widthControl.setValue(imageSize.width);
                heightControl.setValue(imageSize.height);
            }
        }
    }

    onAspectRatioChanged(value: number) {
        this.selectedAspectRatio = value;
        const heightControl = this.imageSizeFormGroup.get('height');
        const aspectRatioControl = this.imageSizeFormGroup.get('ratio');
        aspectRatioControl.setValue(value);
        const w = +this.imageSizeFormGroup.get('width').value;
        const h = Math.round(w / this.selectedAspectRatio);
        heightControl.setValue(h);
    }

    onImageWidthChange(ev) {
        const w = +ev.target.value;
        const h = Math.round(w / this.selectedAspectRatio);
        const widthControl = this.imageSizeFormGroup.get('width');
        const heightControl = this.imageSizeFormGroup.get('height');
        widthControl.setValue(w);
        heightControl.setValue(h);
    }

    onSizeSliderChanged(ev: any) {
        this.watermarkFormGroup.get('size').setValue(ev.target.value);
    }

    onOpacitySliderChanged(ev: any) {
        this.watermarkFormGroup.get('opacity').setValue(ev.target.value);
    }

    onOffsetXSliderChanged(ev: any) {
        this.watermarkFormGroup.get('offsetX').setValue(ev.target.value);
    }

    onOffsetYSliderChanged(ev: any) {
        this.watermarkFormGroup.get('offsetY').setValue(ev.target.value);
    }

    getForegroundStyle(tempWatermarkUrl, memberWatermarkUrl) {
        const hasWatermark = !isEmpty(tempWatermarkUrl || memberWatermarkUrl);
        const watermarkSizePercentage = +this.watermarkFormGroup.get('size').value / 100.0;
        const watermarkOpacity = +this.watermarkFormGroup.get('opacity').value / 100.0;
        const {width: imageSizeWidth, height: imageSizeHeight} = this.imageSizeFormGroup.getRawValue();
        const offsetX = +this.watermarkFormGroup.get('offsetX').value;
        const offsetY = +this.watermarkFormGroup.get('offsetY').value;
        const scaledWatermarkSize = {
            width: watermarkSizePercentage * this.currentWatermarkTrueSize.width * (this.dummyBackgroundPixelSize * this.selectedAspectRatio / imageSizeWidth),
            height: watermarkSizePercentage * this.currentWatermarkTrueSize.height * (this.dummyBackgroundPixelSize / imageSizeHeight)
        };
        return {
            width: `${scaledWatermarkSize.width}px`,
            height: `${scaledWatermarkSize.height}px`,
            top: `${offsetY}%`,
            left: `${offsetX}%`,
            opacity: watermarkOpacity,
            visibility: hasWatermark ? 'visible' : 'hidden'
        };
    }

    closeDialog() {
        this.dialogRef.close();
    }

    async addAgency() {
        this.loadingWatermark = true;
        const agency: Agency = {
            id: this.data ? this.data.id : null,
            hasWatermark: !!this.watermarkFile || this.watermarkFound,
            active: this.data ? this.data.active : true,
            name: this.primaryDetailsFormGroup.get('name').value,
            contactNumber: this.primaryDetailsFormGroup.get('contactNumber').value,
            address: this.primaryDetailsFormGroup.get('address').value,
            invoiceEmails: this.invoiceEmails.controls.map(control => control.value),
            notificationEmails: this.notificationEmails.controls.map(control => control.value),
            notes: JSON.stringify(this.primaryDetailsFormGroup.get('notes').value),
            imageSize: {
                width: this.imageSizeFormGroup.get('width').value,
                height: this.imageSizeFormGroup.get('height').value,
                ratio: this.imageSizeFormGroup.get('ratio').value
            },
            watermarkSize: this.watermarkFormGroup.get('size').value,
            watermarkOpacity: this.watermarkFormGroup.get('opacity').value,
            watermarkOffset: {
                offsetX: +this.watermarkFormGroup.get('offsetX').value,
                offsetY: +this.watermarkFormGroup.get('offsetY').value
            },
            watermarkDimensions: {
                width: this.currentWatermarkTrueSize.width,
                height: this.currentWatermarkTrueSize.height
            }
        };
        if (agency.id) {
            this.data.watermarkSize = agency.watermarkSize;
            this.isPosting = true;
            if (this.watermarkRemoveRequested) {
                this.removeWatermark();
            } else {
                await this.uploadWatermark(agency.id);
            }
            agency.hasWatermark = !!this.watermarkFile || this.watermarkFound;
            this.agencyService.putAgency(agency).pipe(take(1)).subscribe(refId => {
                refreshJobs$.next();
                refreshJobsUsers$.next();
                this.snackbarService.showSnackbar(`${agency.name} updated`);
                this.isPosting = false;
                this.dialogRef.close({agencyId: refId});
            }, err => {
                this.isPosting = false;
                console.error(err);
                this.snackbarService.handleError('Problem updating agency');
            });
        } else {
            this.isPosting = true;
            this.agencyService.postAgency(agency).subscribe(async refId => {
                await this.uploadWatermark(refId);
                this.snackbarService.showSnackbar(`${agency.name} added`);
                this.isPosting = false;
                refreshJobsUsers$.next();
                this.dialogRef.close({agencyId: refId});
            }, err => {
                this.isPosting = false;
                console.error(err);
                this.snackbarService.handleError('Problem adding agency');
            });
        }
    }

    uploadWatermark(agencyId) {
        return new Promise((resolve, reject) => {
            if (!!this.watermarkFile) {
                this.assetsService.setWatermarkLocal(agencyId, this.watermarkFile['dataURL']);

                this.assetsService.getWatermarkUploadUrl(this.watermarkFile.type, agencyId, this.watermarkFile.name)
                    .toPromise()
                    .then((res: Response) => {
                        this.loadingWatermark = false;
                        this.assetsService.uploadWatermark(res.data, this.watermarkFile)
                            .pipe(take(1))
                            .toPromise()
                            .then(() => {
                                resolve(null);
                            })
                            .catch((err) => {
                                this.snackbarService.handleError('Watermark could not be stored');
                                reject(new Error(err.message));
                            });
                    })
                    .catch(e => console.error('COULD NOT UPLOAD DATA:', e.message));
            } else {
                resolve(null);
            }

        });
    }

    checkNotificationEmailValidity(value) {
        this.notificationEmailListHasError = !EMAIL_REGEX.exec(value);
        return EMAIL_REGEX.exec(value);
    }

    checkInvoiceEmailValidity(value) {
        this.invoiceEmailListHasError = !EMAIL_REGEX.exec(value);
        return EMAIL_REGEX.exec(value);
    }

    removeWatermark() {
        if (!!this.data) {
            const agencyId = this.data.id;
            this.watermarkFile = null;
            this.assetsService.removeWatermark(agencyId)
                .toPromise()
                .then(() => {
                    this.snackbarService.showSnackbar('Watermark removed.');
                    this.assetsService.setWatermarkLocal('temp', null);
                    this.assetsService.setWatermarkLocal(`${agencyId}`, null);
                    this.watermarkDropzone.removeAllFiles(true);
                    this.hasUnsavedWatermark = false;
                    this.watermarkFound = false;
                })
                .catch(() => {
                    this.snackbarService.handleError('Error while removing watermark. Please try again.');
                });
        } else {
            this.assetsService.setWatermarkLocal('temp', null);
            this.watermarkFile = null;
            this.watermarkDropzone.removeAllFiles(true);
            this.hasUnsavedWatermark = false;
            this.watermarkFound = false;
        }
    }
}
